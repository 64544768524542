@import "../../../common/theme_variables";
@import "../../../mixins/transitions";
@import "../../../mixins/shadows";

#htmlcontent-top{
  h1{
    margin-bottom: 30px;
  }
  .row{
    position: relative;
    margin: 0;
    @media(min-width:992px){
      background-position: top left;
      background-repeat: no-repeat !important;
      background-size: contain !important;
    }
    @media(max-width:991px){
      background: none !important;
    }

    div.col-md-8{
      position: static;
    }

    
    div.col-md-6{
      @media(min-width:992px){
        float: right;
        margin-top: 30px;
        margin-right: 15px;
      }
      @media(max-width:991px){
        margin-top: 30px;
        padding: 0;
      }
    }
  }
}

#htmlcontent-top,
#htmlcontent-bottom{
  article{
    background: $grey;
    padding: 30px;

    h2,
    h3{
      margin-top: 0;
      margin-bottom: 20px;
      padding: 0;
    }

    .btn{
      margin-top: 20px;
    }
  }
}

#htmlcontent-bottom{
  .row{
    position: relative;
    margin: 0;
    @media(min-width:992px){
      background-position: top left;
      background-repeat: no-repeat !important;
      background-size: contain !important;
    }
    @media(max-width:991px){
      background: none !important;
    }

    div.col-md-8{
      position: static;
    }

    
    div.col-md-6{
      @media(min-width:992px){
        float: right;
        margin-top: 30px;
        margin-right: 15px;
      }
      @media(max-width:991px){
        margin-bottom: 30px;
        padding: 0;
      }
    }
  }
}

div[class^="htmlcontent-item-"],
div[class*=" htmlcontent-item-"]{
  height: 270px;
  background-color: $black;
  background-position: top left;
  background-repeat: no-repeat;
  background-size: cover;
  color: $white;
  margin-bottom: 30px;
  text-align: center;
  vertical-align: middle;

  a{
    display: inline-block;
    position: relative;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    padding: 20px;
    background: rgba(0,0,0,0.1);
    color: $white;
    line-height: 230px;
    vertical-align: middle;
    @include transition(all,ease,0.3s);

    &:before,
    &:after{
      position: absolute;
      z-index: 1;
      bottom: -10px;
      content: "";
      background: transparent;
      display: block;
      @include transition(all,ease,0.3s);
    }

    &:before{
      width: 10px;
      height: 150px;
      left: -10px;
    }
    &:after{
      width: 150px;
      height: 10px;
      left: 0px;
    }

    &:hover,
    &:focus{
      text-decoration: none;
      background: rgba(0,0,0,0.25);
      
      &:before,
      &:after{
        background: $golden;
      }
      

    }
    h2,
    h3{
      display:inline-block;
      margin: 0;
      padding: 0;
      width: 100%;
      font-weight: normal;
      font-family: $font;
      font-size: 24px;
      vertical-align: middle;
      text-shadow: 0px 0px 3px rgba($black, 1);

      &:after{
        content: 'En savoir +';
        display: block;
        background: $white;
        color: $black;
        border-radius:25px;
        font-size: 14px;
        width: 120px;
        padding: 8px 20px;
        margin: 10px auto 0 auto;
        text-shadow: none;
      }
    }
  }
}

#htmlcontent_reinsurance{

  @media(max-width:767px){
    padding-top: 30px;
    padding-bottom: 30px;

    .col-sm-4:not(:last-child){
      &:after{
        content: "";
        display: block;
        width: 80%;
        height: 1px;
        background: lighten($black,60%);
        margin: 20px auto;
      }
    }
  }


  @media(min-width:768px){
    padding-top: 30px;
    padding-bottom: 60px;
  
    .row{
      display: table;
      width: 100%;
      margin: 0;

      .col-sm-4{
        display: table-cell;
        float: none;
        padding-left: 60px;
        padding-right: 60px;
        font-size: 18px;

        &:not(:last-child):after{
          content: "";
          display: block;
          width: 1px;
          height: 80%;
          position: absolute;
          top: 50%;
          right: 0;
          z-index: 1;
          transform: translateY(-50%);
          -webkit-transform: translateY(-50%);
          background: lighten($black,60%);
        }
      }
    }
  }

  h3{
    font-size: 24px;
    color: $golden;
  }
}